export enum UserEventType {
	Unknown = "",
	Alert = "Alert",
	Collaboration = "Collaboration",
	Export = "Export"
}

export interface IUserEvent {
	eventIds: number[];
	eventType: UserEventType;
	message: string;
	actionCaption: string;
	actionUrl: string;
	isDialogAction: boolean;
}
