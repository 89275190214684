import React, { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { sessionCleanupManager } from "@common/SessionCleanupManager";

interface SessionDataPipelineContextType {
	registerModuleCleanup: (moduleName: string) => void;
}

const SessionDataPipelineContext = createContext<SessionDataPipelineContextType | null>(null);

export const SessionDataPipelineProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { pathname } = useLocation();

	const registerModuleCleanup = useCallback((moduleName: string) => {
		sessionCleanupManager.registerModuleCleanup(moduleName);
	}, []);

	const contextValue = useMemo(
		() => ({
			registerModuleCleanup
		}),
		[registerModuleCleanup]
	);

	useEffect(() => {
		sessionCleanupManager.getRegisteredModules().forEach(moduleName => {
			if (!pathname.includes(`/${moduleName}`)) {
				sessionCleanupManager.runAndClear(moduleName);
			}
		});
	}, [pathname]);

	return <SessionDataPipelineContext.Provider value={contextValue}>{children}</SessionDataPipelineContext.Provider>;
};

export const useSessionDataPipeline = (): SessionDataPipelineContextType => {
	const context = useContext(SessionDataPipelineContext);

	if (!context) {
		throw new Error("useSessionDataPipeline must be used within a SessionDataPipelineProvider");
	}

	return context;
};
