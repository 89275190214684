import { postText, postVoid } from "@mede/react-library/utils";
import { IMenuNavigationEvent } from "@components/Menu/MenuModel";

export namespace eventTracerApi {
	export async function traceMenuNavigation(event: IMenuNavigationEvent): Promise<boolean> {
		const result = await postText("platform-api/trace/menu-navigation", event);
		return result.includes("true");
	}

	export function updateFavoriteUsage(id: string) {
		return postVoid("platform-api/trace/update-favorite-usage", { id });
	}
}
