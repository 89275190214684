/* eslint-disable */
import { legacyApplicationApi } from "@legacyApp/LegacyApplicationApi";
import { getDataFromSession, saveDataInSession } from "@mede/react-library/utils";
import { PENDO_OPTIONS } from "@common/constants";

type IPendoEventData = {
	trackType: string;
	metadata: any;
};

class PendoService {
	private readonly delayedEvents: IPendoEventData[] = [];

	private isStarted = false;
	private isPendoDisabled = false;

	public async init() {
		let pendoOptions = getDataFromSession<PendoOptions>(PENDO_OPTIONS);
		if (pendoOptions == null) {
			pendoOptions = (await legacyApplicationApi.getPendoOptions()) as PendoOptions;
			if (pendoOptions == null) {
				this.isPendoDisabled = true;
				return;
			}

			saveDataInSession(PENDO_OPTIONS, pendoOptions);
		}

		window.pendoOptions = pendoOptions;

		try {
			await this.loadPendoScript(pendoOptions.apiKey);

			window.pendo.initialize({
				apiKey: pendoOptions.apiKey,
				visitor: pendoOptions.visitor,
				account: pendoOptions.account
			});

			this.isStarted = true;

			this.delayedEvents.forEach(x => {
				this.trackEvent(x);
			});
		} catch (e: any) {
			this.isPendoDisabled = true;
			console.error(e);
		}
	}

	public trackEvent(data: IPendoEventData) {
		if (data == null || this.isPendoDisabled) {
			return;
		}

		if (!this.isStarted) {
			this.delayedEvents.push(data);
			return;
		}

		try {
			window.pendo.track(data.trackType, data.metadata);
		} catch (e: any) {
			console.error(e);
		}
	}

	private loadPendoScript(apiKey: string) {
		const p = window as any;
		const e = document;
		const n = "script";
		const d = "pendo";

		return new Promise<void>((resolve, reject) => {
			let v: any, w, x, y, z;
			const o = (p[d] = p[d] || {});
			o._q = [];
			v = ["initialize", "identify", "updateOptions", "pageLoad"];
			for (w = 0, x = v.length; w < x; ++w)
				(function (m) {
					o[m] =
						o[m] ||
						function () {
							o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
						};
				})(v[w]);
			y = e.createElement(n);
			y.async = !0;
			y.src = "https://content.tracking.medeanalytics.com/agent/static/" + apiKey + "/pendo.js";
			y.onload = () => resolve();
			y.onerror = () => reject(new Error("Pendo: something went wrong"));
			z = e.getElementsByTagName(n)[0];
			z.parentNode!.insertBefore(y, z);
		});
	}
}

let pendoInstance: PendoService;

interface PendoOptions {
	apiKey: string;
	visitor: unknown;
	account: unknown;
}

export function initPendoService() {
	pendoInstance = new PendoService();
	pendoInstance.init();
}

export function trackPendoEvent(data: IPendoEventData) {
	if (pendoInstance != null) {
		pendoInstance.trackEvent(data);
	}
}
