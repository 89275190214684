import { IEpmConfiguration } from "@common/common-types";
import { RootState } from "@redux/store";
import { IEpmState } from "@redux/defaultStates/defaultEpmState";
import { IIdentifiable } from "@epm/Controls/BaseModels";

export function isInsightsEpmEnabled(epmCfg: IEpmConfiguration | null): boolean {
	return epmCfg !== null && epmCfg?.insightsEpmEnabled === true;
}

export function trimTrailingSlash(str: string): string {
	return str.replace(/\/$/, "");
}

export function getEpmState(state: RootState | IEpmState): IEpmState {
	if ("epm" in state) {
		return state.epm as IEpmState;
	}

	return state as IEpmState;
}

let epmApiPath: string = "";
export function buildEpmApiUrl(endpoint: string): string {
	return `${trimTrailingSlash(epmApiPath)}/${endpoint}`;
}

export function storeEpmApiPath(value: string) {
	epmApiPath = value;
}

export function delayCall<T>(func: () => T, delay: number = 0): Promise<T> {
	return new Promise(resolve => setTimeout(() => resolve(func()), delay));
}

export function findById<T extends IIdentifiable>(source?: T[], ids?: number[]): T[] {
	if (!ids || !source) {
		return [];
	}

	const idSet = new Set(ids);

	return source.filter(item => idSet.has(item.id));
}

export function applyActionIfSingle<T>(items: T[], singleItemAction: (item: T) => void): void {
	if (items.length == 1) {
		return singleItemAction(items[0]);
	}
}

export function applyActionToItems<T>(
	items: T[],
	singleItemAction: (item: T) => void,
	multipleItemsAction: (items: T[]) => void
): void {
	applyActionIfSingle(items, singleItemAction);

	if (items.length > 1) {
		multipleItemsAction(items);
	}
}
