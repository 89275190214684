import { useCallback, useEffect } from "react";
import { notificationManager } from "@common/NotificationManager";
import HubProxy from "@common/HubProxy";
import { ALERTS_COUNT_EVENT, NOT_VIEWED_EVENTS } from "@common/constants";
import { IUserEvent, UserEventType } from "@components/App/EventNotifier/IUserEvent";
import { useAppDispatch, useAppSelector } from "@redux/reduxHooks";
import { setAlertsCount, setCollaborations } from "@components/Menu/menuSlice";

export default function useEventNotifier() {
	const hubProxy = HubProxy();
	const dispatch = useAppDispatch();
	const isLeftMenu = useAppSelector(x => x.app.configuration.leftMenuEnabled);

	const showNewEvents = useCallback(
		(events: IUserEvent[]) => {
			const collaborationsEvent = events.find(x => x.eventType === UserEventType.Collaboration);
			const exportsEvent = events.find(x => x.eventType === UserEventType.Export);

			if (exportsEvent != null) {
				notificationManager.showEvent(exportsEvent);
			}

			if (isLeftMenu) {
				dispatch(setCollaborations(collaborationsEvent?.eventIds ?? []));
			} else {
				const alertsEvent = events.find(x => x.eventType === UserEventType.Alert);
				if (alertsEvent != null) {
					notificationManager.showEvent(alertsEvent);
				}

				if (collaborationsEvent != null) {
					notificationManager.showEvent(collaborationsEvent);
				}
			}
		},
		[isLeftMenu]
	);

	const showAlertsCount = useCallback((alertsCount: number) => {
		dispatch(setAlertsCount(alertsCount));
	}, []);

	useEffect(() => {
		hubProxy.subscribe(NOT_VIEWED_EVENTS, showNewEvents);
		hubProxy.subscribe(ALERTS_COUNT_EVENT, showAlertsCount);

		return () => {
			hubProxy.unsubscribe(NOT_VIEWED_EVENTS, showNewEvents);
			hubProxy.unsubscribe(ALERTS_COUNT_EVENT, showAlertsCount);
		};
	}, [showNewEvents]);
}
