import { IMenuNavigationEvent } from "./MenuModel";
import { eventTracerApi } from "@common/eventTracerApi";

export const MENU_NAVIGATION_EVENT = "menu-navigation-event";
let isPending: boolean = false;
export namespace menuEventTracerService {
	export function traceNavigation(): void {
		const storedEvent = readEventFromStorage();
		if (isPending || storedEvent == null) {
			return;
		}

		isPending = true;
		eventTracerApi
			.traceMenuNavigation(storedEvent)
			.catch(error => console.error(`Couldn't trace menu navigation event: ${error}`))
			.finally(() => {
				localStorage.removeItem(MENU_NAVIGATION_EVENT);
				isPending = false;
			});
	}

	export function saveNavigationEventToStorage(event: IMenuNavigationEvent): void {
		const storedEvent = readEventFromStorage();
		if (storedEvent != null) {
			traceNavigation();
		}

		localStorage.setItem(MENU_NAVIGATION_EVENT, JSON.stringify(event));
	}

	function readEventFromStorage(): IMenuNavigationEvent | null {
		const eventJson = localStorage.getItem(MENU_NAVIGATION_EVENT);
		if (eventJson == null) {
			return null;
		}

		return JSON.parse(eventJson);
	}
}
