import { AuthStorage } from "@components/Initiatives/api/auth/AuthStorage";
import { AuthData } from "@components/Initiatives/api/auth/AuthData";
import { createDefaultErrorMsg } from "@mede/react-library/utils";

export interface IAuthDataRequest {
	host: string;
	headers: {
		Authorization: string;
	};
}

export type ErrorAuth = {
	error: string;
};

export abstract class AuthServiceBase {
	private readonly authStorage: AuthStorage;
	private static fetching: Promise<IAuthDataRequest> | null = null;
	private readonly authorizationTokenType = {
		BEARER: "Bearer"
	};

	protected constructor(authStorage: AuthStorage) {
		this.authStorage = authStorage;
	}

	public async makeAuth(): Promise<IAuthDataRequest> {
		const activeAuthData = this.authStorage.get();
		if (activeAuthData != null && !activeAuthData.isExpired()) {
			return this.mapAuthToken(activeAuthData);
		}

		AuthServiceBase.fetching ??= this.fetchAndRefreshToken(activeAuthData);
		return AuthServiceBase.fetching;
	}

	private async fetchAndRefreshToken(activeAuthData: AuthData | null): Promise<IAuthDataRequest> {
		const request = !activeAuthData?.isRefreshTokenEnabled()
			? this.getToken()
			: this.refreshToken(activeAuthData.refresh_token, activeAuthData.sessionId);

		const data = await request
			.catch(reason => {
				const model = (reason.data ?? null) as ErrorAuth | null;
				if (model?.error != null && model.error.length > 0) {
					return Promise.reject("TokenInvalid");
				}

				throw new Error(reason?.message ?? createDefaultErrorMsg());
			})
			.finally(() => (AuthServiceBase.fetching = null));

		this.authStorage.set(data);

		return this.mapAuthToken(data);
	}

	protected abstract getToken(): Promise<AuthData>;

	protected abstract refreshToken(refresh_token: string, sessionId: number): Promise<AuthData>;

	public getCurrentUserId(): number | undefined {
		const authTokenInfo = this.authStorage.get();
		return authTokenInfo != null ? authTokenInfo.userId : NaN;
	}

	private mapAuthToken(authTokenInfo: AuthData): IAuthDataRequest {
		return {
			host: authTokenInfo.host,
			headers: {
				Authorization: `${this.authorizationTokenType.BEARER} ${authTokenInfo.access_token}`
			}
		};
	}
}
