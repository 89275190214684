import { injectAbortHandler } from "@mede/react-library/utils";
import history from "history/browser";

const abortControllers: Dictionary<AbortController> = {};

export function initAbortHandler() {
	injectAbortHandler(() => {
		return getAbortController().signal;
	});

	let lastPathname = location.pathname;
	history.listen(() => {
		if (lastPathname == location.pathname) {
			return;
		}

		lastPathname = location.pathname;
		abortPreviousRequests(lastPathname);
	});
}

function abortPreviousRequests(pathname: string) {
	const keys: string[] = [];
	for (const key in abortControllers) {
		if (key != pathname) {
			keys.push(key);
		}
	}

	for (const key of keys) {
		abortControllers[key].abort();
		delete abortControllers[key];
	}
}

function getAbortController() {
	const pathname = location.pathname;
	let controller = abortControllers[pathname];
	if (controller == null) {
		abortControllers[pathname] = controller = new AbortController();
	}

	return controller;
}
