import { IPromptDialogProps, usePromptDialogs } from "@components/Dialog/PromptProvider";
import { MedeConfirmationDialog } from "@mede/react-library/components";
import { useMemo, useState } from "react";

export default function PromptDialogsContainer() {
	const { dialogs, removeDialog } = usePromptDialogs();

	return (
		<>
			{dialogs.map(dialog => (
				<PromptDialog key={dialog.id} dialogProps={dialog.props} onClosed={() => removeDialog(dialog.id)} />
			))}
		</>
	);
}

function PromptDialog({ dialogProps, onClosed }: { dialogProps: IPromptDialogProps; onClosed: () => void }) {
	const [isOpen, setIsOpen] = useState<boolean>(true);

	const confirmHandler = useMemo(() => {
		if (!dialogProps.confirmHandler && !dialogProps.confirmBtnText) {
			return undefined;
		}

		return () => {
			dialogProps.confirmHandler?.();
			setIsOpen(false);
		};
	}, []);

	const cancelHandler = useMemo(() => {
		if (!dialogProps.cancelHandler && !dialogProps.cancelBtnText) {
			return undefined;
		}

		return () => {
			dialogProps.cancelHandler?.();
			setIsOpen(false);
		};
	}, []);

	return (
		<MedeConfirmationDialog
			{...dialogProps}
			isOpen={isOpen}
			confirmHandler={confirmHandler}
			cancelHandler={cancelHandler}
			onClosed={e => {
				onClosed();
				dialogProps.onClosed?.(e);
			}}
		/>
	);
}
