import { Button, Classes, Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";
import { DialogEntity, DialogType, IDialogSettings } from "@components/Dialog/DialogEntity";
import { createRef, SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "@redux/reduxHooks";
import { removeDialog, setDialogReturnModel } from "@components/Dialog/dialogSlice";
import { MedeSpinner } from "@mede/react-library/components";

export default function MedeLegacyDialog(props: Readonly<{ dialog: DialogEntity; container?: HTMLElement }>) {
	const { id: dialogId, dialogType, settings, dialogResizeTrigger, getReturnModel } = props.dialog;
	const dispatch = useAppDispatch();
	const [isOpen, setIsOpen] = useState(true);
	const [width, setWidth] = useState(settings.width);
	const [height, setHeight] = useState(settings.height ? settings.height + 2 : undefined);
	const returnModelRef = useRef(getReturnModel);

	useEffect(() => {
		setIsOpen(props.dialog.isOpen);
	}, [props.dialog.isOpen]);

	useEffect(() => {
		returnModelRef.current = getReturnModel;
	}, [getReturnModel]);

	const onOpened = useCallback((node: HTMLElement) => {
		(node.parentElement!.querySelector("." + Classes.OVERLAY_START_FOCUS_TRAP) as HTMLElement)?.focus();
	}, []);

	const closeDialog = useCallback(() => {
		setIsOpen(false);
	}, []);

	const onClosed = useCallback(() => {
		settings.returnResultCallback?.(returnModelRef.current());
		settings.closeDialogCallback?.();
		if (settings.returnFocusTargetSelector) {
			(document.querySelector(settings.returnFocusTargetSelector) as HTMLElement)?.focus();
		}

		dispatch(removeDialog(props.dialog.id));
	}, []);

	const onChangeSize = useCallback((width: number, height: number) => {
		setWidth(width);
		setHeight(height);
	}, []);

	return (
		<Dialog
			isOpen={isOpen}
			portalContainer={props.container}
			transitionDuration={testMode ? 0 : undefined}
			canEscapeKeyClose={settings.isHideByEscKey}
			canOutsideClickClose={settings.closeOnBackdrop}
			shouldReturnFocusOnClose={settings.returnFocusTargetSelector == null}
			title={settings.headerText ? settings.headerText : null}
			className={`mede-dialog${dialogType === DialogType.Confirm ? " mede-confirm" : ""} ${settings.dialogClass ?? ""}`}
			style={{ height: height, width: width }}
			autoFocus={true}
			onOpened={onOpened}
			onClose={closeDialog}
			onClosed={onClosed}
		>
			{dialogType === DialogType.Frame ? (
				<FrameContent
					settings={settings}
					dialogId={dialogId}
					onClose={closeDialog}
					onChangeSize={onChangeSize}
					resizeTrigger={dialogResizeTrigger}
				/>
			) : (
				<PromptContent
					settings={settings}
					dialogId={dialogId}
					onClose={closeDialog}
					dialogType={dialogType}
					getReturnModel={getReturnModel}
				/>
			)}
		</Dialog>
	);
}

function FrameContent(
	props: Readonly<{
		settings: IDialogSettings;
		dialogId: number;
		onClose: () => void;
		onChangeSize: (width: number, height: number) => void;
		resizeTrigger: string | null;
	}>
) {
	const { settings, dialogId, resizeTrigger } = props;
	const [isLoading, setIsLoading] = useState(true);
	const frame = createRef<HTMLIFrameElement>();

	useEffect(() => {
		if (resizeTrigger != null && frame.current != null) {
			resizeDialog(frame.current);
		}
	}, [resizeTrigger]);

	const onFrameLoaded = useCallback((e: SyntheticEvent<HTMLIFrameElement>) => {
		const frame = e.currentTarget;
		if (settings.isHideByEscKey) {
			frame.contentWindow!.addEventListener("keyup", event => {
				if (event.key === "Escape") {
					props.onClose();
				}
			});
		}

		if (settings.fitDialogContent) {
			resizeDialog(frame);
		}

		setIsLoading(false);
		frame.contentDocument!.body?.setAttribute("tabindex", "-1");
		frame.contentDocument!.body?.focus();
		settings.contentReadyCallback?.();
	}, []);

	function resizeDialog(frame: HTMLIFrameElement) {
		const width = frame.contentDocument!.body.offsetWidth;
		const height = frame.contentDocument!.body.offsetHeight;
		props.onChangeSize(width, height);
	}

	return (
		<DialogBody useOverflowScrollContainer={false} className="frame-body">
			{isLoading ? <MedeSpinner intent="primary" className="frame-loader" aria-label="Dialog is loading" /> : null}
			<iframe
				title="Dialog frame"
				className="css_ModalWindowFrame"
				data-testid="modal-dialog-iframe"
				data-dialog-id={dialogId}
				src={settings.url}
				onLoad={onFrameLoaded}
				ref={frame}
				style={{ visibility: isLoading ? "hidden" : "visible" }}
			/>
		</DialogBody>
	);
}

function PromptContent(
	props: Readonly<{
		dialogType: DialogType;
		settings: IDialogSettings;
		dialogId: number;
		onClose: () => void;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		getReturnModel: () => any;
	}>
) {
	const { dialogType, settings, dialogId, onClose, getReturnModel } = props;
	const dispatch = useAppDispatch();

	const okButtonClicked = useCallback(() => {
		dispatch(
			setDialogReturnModel(dialogId, {
				...getReturnModel(),
				confirmValue: true
			})
		);

		onClose();
	}, [getReturnModel]);

	return (
		<>
			<DialogBody useOverflowScrollContainer={false}>
				<div dangerouslySetInnerHTML={{ __html: settings.messageText! }} />
			</DialogBody>
			<DialogFooter
				minimal={true}
				actions={
					<>
						<Button text={settings.okButtonText ?? "OK"} intent={settings.okButtonIntent} onClick={okButtonClicked} />
						{settings.cancelButtonText || dialogType === DialogType.Confirm ? (
							<Button text={settings.cancelButtonText ?? "Cancel"} onClick={onClose} />
						) : null}
					</>
				}
			></DialogFooter>
		</>
	);
}
