import { AuthData } from "@components/Initiatives/api/auth/AuthData";

export class AuthStorage {
	private readonly platformSessionKey = "SESSION";
	private readonly storageKey = "EPM_AUTH";
	private readonly orgUnit: string | null;

	constructor(orgUnit: string | null) {
		this.orgUnit = orgUnit;
		this.validateStorage();
	}

	public set(auth: AuthData): void {
		if (!auth) {
			throw new Error("AuthStorage.set: auth is undefined");
		}

		window.localStorage.setItem(this.getStorageKey(), JSON.stringify(auth));
	}

	public get(): AuthData | null {
		const storageValue = window.localStorage.getItem(this.getStorageKey());
		if (!storageValue) {
			return null;
		}

		return new AuthData(JSON.parse(storageValue));
	}

	private getStorageKey(): string {
		return `${this.platformSessionKey}_${this.storageKey}`;
	}

	private validateStorage(): void {
		const authData = this.get();
		if (authData !== null && this.orgUnit !== null && authData.orgUnit !== this.orgUnit) {
			window.localStorage.removeItem(this.getStorageKey());
		}
	}
}
