import { lazy, Suspense, useEffect } from "react";
import { loadMenu } from "@components/Menu/menuSlice";
import { menuEventTracerService } from "@components/Menu/menuEventTracerService";
import { useAppDispatch, useAppSelector } from "@redux/reduxHooks";
import LeftMenuWrapper from "./LeftMenu/LeftMenuWrapper";

const Menu = lazy(() => import("@components/Menu/Menu"));

export default function MenuGeneric(props: Readonly<{ container?: HTMLElement }>) {
	const dispatch = useAppDispatch();
	const isReady = useAppSelector(s => s.app.configuration.hasAccess);
	const isLeftMenuEnabled = useAppSelector(s => s.app.configuration.leftMenuEnabled);

	useEffect(() => {
		menuEventTracerService.traceNavigation();
	}, []);

	useEffect(() => {
		dispatch(loadMenu());
	}, [isReady]);

	if (!isLeftMenuEnabled) {
		// Lazy import works only in native React App where we rarely use TopMenu. For menu-external TopMenu will always be included
		return (
			<div id="top-menu">
				<Suspense>
					<Menu container={props.container} />
				</Suspense>
			</div>
		);
	}

	return <LeftMenuWrapper container={props.container} />;
}
