import type { IBoardModel } from "@components/Board/BoardModel";

const defaultBoardState: IBoardModel = {
	isLoading: true,
	page: null,
	selectedTab: "",
	widgetsData: {},
	filtersPanel: { filterAxes: [], quickFilters: [], isLoaded: false },
	isEditMode: false
};

export default defaultBoardState;
