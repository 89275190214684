import { parseMenuItem } from "./MenuItemHandler";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { KeyboardEventHandler, MouseEvent, ReactNode } from "react";
import { IMenuItemDto, IMenuNavigationEvent, ReportOpenedLocationType } from "./MenuModel";
import { useAppDispatch } from "@redux/reduxHooks";
import { setPage } from "./menuSlice";
import { menuEventTracerService } from "./menuEventTracerService";
import { AppDispatch } from "@redux/store";
import { PopoverProps, MenuItem } from "@blueprintjs/core";
import { nonEmpty } from "@mede/react-library/utils";
import { MedeIcon } from "@mede/react-library/components";
import { IconIdentity } from "@mede/react-library/core";

export interface IMenuNavigationParams {
	data: IMenuItemDto;
	path?: Array<string>;
	openedLocationType?: ReportOpenedLocationType;
	navigationType?: string;
	caption?: string;
	beforeNavigation?: () => void;
	productId?: string;
}

interface IMedeMenuItemProps extends IMenuNavigationParams {
	id?: string;
	testId?: string | null;
	text?: ReactNode;
	icon?: IconIdentity;
	popoverProps?: PopoverMenuItemProps;
	children?: ReactNode;
	selected?: boolean;
	disabled?: boolean;
	active?: boolean;
	onKeyDown?: KeyboardEventHandler<HTMLAnchorElement>;
	activeItemId?: string;
	className?: string;
	overriddenProps?: OverridenMenuItemProps;
}

export type OverridenMenuItemProps = {
	onClick?: (e: MouseEvent<HTMLElement>) => void;
	icon?: IconIdentity;
	selected?: boolean;
	disabled?: boolean;
};
export type PopoverMenuItemProps = Partial<Omit<PopoverProps, "content" | "minimal">>;

export default function MedeMenuItem(props: Readonly<IMedeMenuItemProps>): JSX.Element {
	const data = props.data;
	const page = parseMenuItem(data, props.path);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	function onMenuItemClick(e: MouseEvent): void {
		e.preventDefault();
		if (props.children != null) {
			return;
		}

		performMenuNavigation(props, dispatch, navigate);
	}

	const icon = props.icon ?? props.overriddenProps?.icon;
	const href = props.children == null ? (nonEmpty(page?.url) ?? undefined) : undefined;
	return (
		<MenuItem
			className={props.className}
			id={props.id}
			data-testid={props.testId}
			text={props.text ?? data.caption}
			href={href}
			target={page?.isNewWindow ? "_blank" : undefined}
			onClick={page?.isNewWindow ? undefined : onMenuItemClick}
			popoverProps={props.popoverProps}
			selected={props.selected}
			disabled={props.disabled}
			active={props.active}
			onKeyDown={props.onKeyDown}
			{...props.overriddenProps}
			icon={icon ? <MedeIcon icon={icon} fixedWidth /> : undefined}
		>
			{props.children}
		</MenuItem>
	);
}

export function performMenuNavigation(
	params: IMenuNavigationParams,
	dispatch: AppDispatch,
	navigate: NavigateFunction
): void {
	params.beforeNavigation?.();
	saveNavigationEvent(params);

	const page = parseMenuItem(params.data, params.path);
	const caption = params.caption ?? params.data.caption;
	dispatch(setPage(page, params.path ?? [caption!], caption!, navigate, params.productId));
}

function getNavigationEvent(params: IMenuNavigationParams): IMenuNavigationEvent | null {
	if (!params.path || params.path.length === 0) {
		return null;
	}

	return {
		url: params.data.url,
		menuCaption: params.data.caption,
		bucketCaption: params.path[0],
		itemType: params.data.itemType,
		openedLocation: params.openedLocationType ?? ReportOpenedLocationType.ProductMenuItem,
		navigationType: params.navigationType
	} as IMenuNavigationEvent;
}

function saveNavigationEvent(params: IMenuNavigationParams): void {
	const event = getNavigationEvent(params);
	if (event == null) {
		return;
	}

	menuEventTracerService.saveNavigationEventToStorage(event);
}
