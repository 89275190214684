import { AuthTokenProvider } from "@components/Initiatives/api/auth/AuthTokenProvider";
import { AuthStorage } from "@components/Initiatives/api/auth/AuthStorage";
import { AuthData } from "@components/Initiatives/api/auth/AuthData";
import { AuthServiceBase } from "@components/Initiatives/api/auth/AuthServiceBase";

export class AuthService extends AuthServiceBase {
	protected readonly authTokenProvider: AuthTokenProvider;

	constructor(authStorage: AuthStorage, authTokenProvider: AuthTokenProvider) {
		super(authStorage);
		this.authTokenProvider = authTokenProvider;
	}

	public async refreshToken(token: string, sessionId: number): Promise<AuthData> {
		return await this.authTokenProvider.refresh(token, sessionId);
	}

	public async getToken(): Promise<AuthData> {
		return await this.authTokenProvider.getBySession();
	}
}
