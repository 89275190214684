import initialState from "@redux/initialState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const trackerSlice = createSlice({
	name: "tracker",
	initialState: initialState.tracker,
	reducers: {
		setProcessingTime: (state, action: PayloadAction<number>) => {
			state.processingTime = action.payload;
		},
		setDeliveryTime: (state, action: PayloadAction<number>) => {
			state.totalDeliveryTime = action.payload;
		},
		startDelivery: state => {
			state.isDeliveryStarted = true;
			state.processingTime = 0;
			performance.mark("DeliveryStarted");
		},
		finishDelivery: state => {
			state.isDeliveryStarted = false;
			performance.mark("DeliveryFinished");
		},
		resetTime: state => {
			state.processingTime = 0;
			state.totalDeliveryTime = 0;
		}
	}
});
export const { setProcessingTime, setDeliveryTime, startDelivery, finishDelivery, resetTime } = trackerSlice.actions;

export default trackerSlice.reducer;
