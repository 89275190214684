import { AuthStorage } from "@components/Initiatives/api/auth/AuthStorage";
import { AuthTokenProvider } from "@components/Initiatives/api/auth/AuthTokenProvider";
import { AuthService } from "@components/Initiatives/api/auth/AuthService";
import { AuthServiceBase } from "@components/Initiatives/api/auth/AuthServiceBase";

export class AuthServiceFactory {
	private static authService: AuthService | null = null;

	public static create(apiUrl: string, owner: string, orgUnit: string | null = null): AuthServiceBase {
		this.authService ??= this.createAuthService(apiUrl, owner, orgUnit);

		return this.authService;
	}

	private static createAuthService(apiUrl: string, owner: string, orgUnit: string | null = null): AuthService {
		const tokenProvider = new AuthTokenProvider(apiUrl, owner);
		const authStorage = new AuthStorage(orgUnit);

		return new AuthService(authStorage, tokenProvider);
	}
}
