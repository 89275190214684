import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppConfiguration, IShareUser } from "@common/common-types";
import { LegacyAppUrls } from "@legacyApp/LegacyAppUrls";
import initialState from "@redux/initialState";
import { AppDispatch, RootState } from "@redux/store";
import { loginApi } from "../Login/loginApi";
import { setXcsrfToken } from "@common/utils";
import {
	CLIENT_TITLE_SESSION_KEY,
	AUTH_ID_KEY,
	XCSRF_TOKEN_KEY,
	LEFT_MENU_ENABLED_KEY,
	THEME_KEY,
	LOGGED_OUT_MESSAGE,
	PRODUCT_ID_SESSION_KEY,
	SESSION_ABOUT_TO_EXPIRE_MESSAGE,
	SESSION_ABOUT_TO_EXPIRE_NOTIFICATION_TIMEOUT
} from "@common/constants";
import { notificationManager, NotificationOptions, NotificationType } from "@common/NotificationManager";
import { CookieManager, getDataFromSession, saveDataInSession } from "@mede/react-library/utils";
import { getShareUsers } from "@common/commonApi";
import { setProductById } from "@components/Menu/menuSlice";

const appSlice = createSlice({
	name: "app",
	initialState: initialState.app,
	reducers: {
		setIsLoggedIn(state, action: PayloadAction<boolean>) {
			state.isLoggedIn = action.payload;
			if (state.isLoggedIn) {
				state.isExpired = false;
			} else {
				window.sessionStorage.clear();
			}
		},
		setLegacyAppMode(state) {
			state.isLegacyAppMode = true;
		},
		setConfiguration(state, action: PayloadAction<IAppConfiguration>) {
			state.configuration = action.payload;
		},
		setExpire(state, action: PayloadAction<boolean>) {
			state.isExpired = action.payload;
		},
		setAboutToExpire(state, action: PayloadAction<boolean>) {
			state.isAboutToExpire = action.payload;
		},
		decreaseSystemAlerts(state) {
			state.configuration.systemAlertIds = state.configuration.systemAlertIds.slice(1);
		},
		setAppLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		},
		setAuthId(state, action: PayloadAction<string>) {
			state.configuration.authId = action.payload;
		},
		setShareUsers(state, action: PayloadAction<IShareUser[]>) {
			state.shareUsers = action.payload;
		},
		setLeavingConfirmationNeeded(state, action: PayloadAction<boolean>) {
			state.leavingConfirmationNeeded = action.payload;
		}
	}
});

export const {
	setIsLoggedIn,
	setLegacyAppMode,
	setConfiguration,
	setExpire,
	setAboutToExpire,
	decreaseSystemAlerts,
	setAppLoading,
	setAuthId,
	setShareUsers,
	setLeavingConfirmationNeeded
} = appSlice.actions;

export function logOut(isByOtherSession: boolean = false) {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		const state = getState();
		if (!state.app.isLoggedIn) {
			return;
		}

		if (!state.app.isProdMode) {
			await loginApi.logOut();
		}

		window.sessionStorage.clear();
		dispatch(setIsLoggedIn(false));
		dispatch(setAboutToExpire(false));

		if (state.app.isProdMode) {
			performLogout(isByOtherSession);
		}
	};
}

export function loadConfiguration() {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		const state = getState();
		const isLegacyAppMode = state.app.isLegacyAppMode;
		const isFirstLoad = getDataFromSession(PRODUCT_ID_SESSION_KEY) == null;
		setXcsrfToken(getDataFromSession<string>(XCSRF_TOKEN_KEY), isLegacyAppMode);

		let configuration: IAppConfiguration;
		try {
			configuration = await loginApi.getConfiguration(isFirstLoad);
		} catch {
			if (state.app.isProdMode) {
				CookieManager.setCookie("logoutMessage", LOGGED_OUT_MESSAGE);
			}

			dispatch(logOut());

			return;
		}

		if (!configuration.hasAccess) {
			redirectToOldUi();
			return;
		}

		saveDataInSession(CLIENT_TITLE_SESSION_KEY, configuration.clientTitle);
		saveDataInSession(XCSRF_TOKEN_KEY, configuration.xcsrfToken);
		setFavIcon(configuration.favIcon);
		setXcsrfToken(configuration.xcsrfToken, isLegacyAppMode);
		localStorage.setItem(THEME_KEY, configuration.theme);
		localStorage.setItem(AUTH_ID_KEY, configuration.authId.toString());
		localStorage.setItem(LEFT_MENU_ENABLED_KEY, configuration.leftMenuEnabled?.toString());
		notificationManager.setTimeout(configuration.notificationTimeout);

		dispatch(setConfiguration(configuration));

		if (configuration.productId) {
			dispatch(setProductById(configuration.productId));
		}
	};
}

export function loadShareEntities() {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		if (getState().app.shareUsers.length > 0) {
			return;
		}

		const shareUsers = await getShareUsers();
		dispatch(setShareUsers(shareUsers));
	};
}

export function continueSession() {
	return async (dispatch: AppDispatch) => {
		await loginApi.continueSession();
		dispatch(setAboutToExpire(false));
	};
}

export function sessionIsAboutToExpire() {
	return (dispatch: AppDispatch, getState: () => RootState) => {
		const isAlreadyKnown = getState().app.isAboutToExpire;
		if (isAlreadyKnown) {
			return;
		}

		dispatch(setAboutToExpire(true));
		notificationManager.showWithAction(
			SESSION_ABOUT_TO_EXPIRE_MESSAGE,
			"Continue",
			undefined,
			() => dispatch(continueSession()),
			NotificationType.Warning,
			new NotificationOptions(SESSION_ABOUT_TO_EXPIRE_NOTIFICATION_TIMEOUT)
		);
	};
}

function redirectToOldUi(): void {
	window.open(LegacyAppUrls.startPage, "_self");
}

function performLogout(isByOtherSession: boolean = false): void {
	const url = isByOtherSession ? LegacyAppUrls.logoutByOtherSession : LegacyAppUrls.logout;
	window.open(url, "_self");
}

function setFavIcon(favIconUrl: string): void {
	if (!favIconUrl) {
		return;
	}

	if (!favIconUrl.startsWith("/") && !favIconUrl.startsWith("http")) {
		favIconUrl = "/" + favIconUrl;
	}

	let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
	if (!link) {
		link = document.createElement("link");
		link.type = "image/x-icon";
		link.rel = "icon";
		document.getElementsByTagName("head")[0].appendChild(link);
	}

	link.href = favIconUrl;
}

export default appSlice.reducer;

export declare type SetAppLoadingCallback = (loading?: boolean) => void;
