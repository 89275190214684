import "opensans-webkit/src/css/open-sans.css";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { FocusStyleManager, OverlayToaster } from "@blueprintjs/core";
import setupStore from "@redux/store";
import App from "@components/App/App";
import { createRoot } from "react-dom/client";
import { notificationManager } from "@common/NotificationManager";
import { LegacyDialogManager } from "@legacyApp/LegacyDialogManager";
import { getBreadcrumbFromState, getProductAndBucketFromState } from "@components/Menu/menuHistoryStateUtils";
import { setBreadcrumb, setMenuBucketCaption, setProductById } from "@components/Menu/menuSlice";
import { injectFetchApiErrorHandler } from "@mede/react-library/utils";
import AppProvider from "@components/App/AppProvider";
import { initAbortHandler } from "@common/RequestAbortHandler";

injectFetchApiErrorHandler((message: string) => notificationManager.showError(message));
initAbortHandler();
FocusStyleManager.onlyShowFocusOnTabs();
document.documentElement.dispatchEvent(new MouseEvent("mousedown")); // Enter "Mouse" mode for FocusStyleManager

window.reactPublicUrl = import.meta.env.VITE_PUBLIC_URL;
window.testMode = false;

const domRoot = createRoot(document.getElementById("root") as HTMLElement);
const store = setupStore();

domRoot.render(
	<StrictMode>
		<ReduxProvider store={store}>
			<BrowserRouter basename={reactPublicUrl ?? "/"}>
				<AppProvider>
					<App />
				</AppProvider>
			</BrowserRouter>
		</ReduxProvider>
	</StrictMode>
);

const toasterRootElement = document.getElementById("root-toast") as HTMLElement;
const toastRoot = createRoot(toasterRootElement);
toastRoot.render(
	<OverlayToaster
		usePortal={false}
		canEscapeKeyClear={false}
		className="notifications-container"
		ref={instance => {
			notificationManager.setToaster(instance!, toasterRootElement, store.dispatch);
		}}
	/>
);

window.platformApplication = {
	dialogManager: new LegacyDialogManager(store)
};

// When Back/Forward navigation inside React app, pick breadcrumb from history
window.addEventListener("popstate", () => {
	const stateBreadcrumb = getBreadcrumbFromState();
	const productAndBucket = getProductAndBucketFromState();
	if (stateBreadcrumb != null) {
		store.dispatch(setBreadcrumb(stateBreadcrumb, true));
	}

	if (productAndBucket?.menuBucketCaption != null) {
		store.dispatch(setMenuBucketCaption(productAndBucket.menuBucketCaption));
	}

	if (productAndBucket?.productId != null) {
		store.dispatch(setProductById(productAndBucket.productId));
	}
});
