import { IMenuItemDto, MenuActionType } from "../MenuModel";

export interface ISearchMenuItemParams {
	caption: string;
	disabled: boolean;
	productId: string;
	locationType: OpenedLocationType;
	level: number;
	url: string;
	actionType: MenuActionType;
	menuItemRef?: IMenuItemDto;
	itemType: string | null;
	pathList: Array<string>;
}

export class SearchMenuItem {
	public caption: string;
	public disabled: boolean;
	public productId: string;
	public locationType: OpenedLocationType;
	public level: number;
	public url: string;
	public actionType: MenuActionType;
	public menuItemRef?: IMenuItemDto;
	public itemType: string | null;
	public pathList: Array<string>;

	constructor(params: ISearchMenuItemParams) {
		this.caption = params.caption;
		this.disabled = params.disabled;
		this.productId = params.productId;
		this.locationType = params.locationType;
		this.level = params.level;
		this.url = params.url;
		this.actionType = params.actionType;
		this.menuItemRef = params.menuItemRef;
		this.itemType = params.itemType;
		this.pathList = params.pathList;
	}

	public get path(): string {
		return this.pathList == null ? "" : this.pathList.join("/");
	}
}

export interface IMenuItemByProduct {
	caption: string;
	url: string;
	actiontype: MenuActionType;
	openedLocationType: OpenedLocationType;
	level: number;
	nodeType: string;
	itemType: string | null;
	pathList: Array<string>;
}

export enum OpenedLocationType {
	personalFavorites = "PersonalFavorites",
	organizationalFavorites = "OrganizationalFavorites",
	personalWorkLists = "PersonalWorkLists",
	organizationalWorkLists = "OrganizationalWorkLists"
}

export interface ISearchResultProductModel {
	menuItemsByProduct: Array<IMenuItemByProduct>;
	productId: string;
	productCaption: string;
}
