import {
	IFactor,
	IGoal,
	IInitiativeDialogModel,
	IInitiativeModel,
	ILeader,
	IOrganization
} from "@components/Initiatives/InitiativesModel";

export const defaultInitiativesState = {
	isLoading: false,
	isRestricted: null as boolean | null,
	initiatives: {} as Dictionary<IInitiativeModel[]>, // initiatives by insight
	organizations: undefined as IOrganization[] | undefined,
	goals: {} as Dictionary<IGoal[] | undefined>, // Goals by organization
	initiativesByGoals: {} as Dictionary<IFactor[] | undefined>, // Insights by organization and goal
	leaders: {} as Dictionary<ILeader[] | undefined>, // Leaders by organization
	createDialogProps: {
		isOpen: false,
		data: null as IInitiativeDialogModel | null
	},
	addMetricDialogProps: {
		isOpen: false,
		data: null as IInitiativeDialogModel | null
	}
};
