import { MedeErrorPage, MedeAnchorButton } from "@mede/react-library/components";

export const PAGE_NOT_FOUND_TITLE = "404";
export const PAGE_NOT_FOUND_SUBTITLE = "Page Not Found.";
export const PAGE_NOT_FOUND_DESCRIPTION = "This page has been removed or doesn't exist";

export default function PageNotFound() {
	return (
		<MedeErrorPage
			title={PAGE_NOT_FOUND_TITLE}
			subtitle={PAGE_NOT_FOUND_SUBTITLE}
			description={PAGE_NOT_FOUND_DESCRIPTION}
		>
			<MedeAnchorButton large intent={"primary"} text="Back to Homepage" href="/LandingPage" />
		</MedeErrorPage>
	);
}
