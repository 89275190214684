import { AuthData } from "@components/Initiatives/api/auth/AuthData";
import { postJson } from "@mede/react-library/utils";

export class AuthTokenProvider {
	private readonly TOKEN_API_PATH = "/token";
	private readonly apiUrl: string;
	private readonly owner: string;

	constructor(apiUrl: string, owner: string) {
		this.apiUrl = apiUrl;
		this.owner = owner;
	}

	public getBySession(): Promise<AuthData> {
		return this.getToken(`grant_type=password&ownerCode=${this.owner}`);
	}

	public refresh(refresh_token: string, sessionId: number): Promise<AuthData> {
		return this.getToken(
			`grant_type=refresh_token&refresh_token=${refresh_token}&sessionId=${sessionId}&ownerCode=${this.owner}`
		);
	}

	private async getToken(requestData: string): Promise<AuthData> {
		const url = `${this.apiUrl.replace(/\/$/, "")}${this.TOKEN_API_PATH}`;
		const requestInit = {
			body: requestData,
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			}
		};

		const auth = await postJson<AuthData>(url, null, { suppressError: true, requestInit });
		return { ...auth, host: this.apiUrl } as AuthData;
	}
}
