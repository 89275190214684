import { IMenuModel, IProductItemDto } from "@components/Menu/MenuModel";
import { isBackForwardNavigation } from "@common/utils";
import {
	NOT_VIEWED_ALERTS_KEY,
	BREADCRUMB_SESSION_KEY,
	CLIENT_TITLE_SESSION_KEY,
	NOT_VIEWED_COLLABORATIONS_KEY,
	LEFT_MENU_ENABLED_KEY,
	AUTH_ID_KEY,
	THEME_KEY,
	LAST_TIME_FILTER_SESSION_KEY
} from "@common/constants";
import { BreadcrumbStoredItem } from "@components/Breadcrumb/Breadcrumbs";
import { IAppConfiguration, IShareUser } from "@common/common-types";
import { DialogEntity } from "@components/Dialog/DialogEntity";
import { IFilterDataModel } from "@components/Insights/FilterPanel/InsightsFilterPanelModels";
import { defaultMenuState } from "@redux/defaultStates/defaultMenuState";
import {
	defaultInsightAnalystData,
	defaultInsightLibraryData,
	defaultInsightPanelData
} from "@redux/defaultStates/defaultInsightState";
import { defaultAlertsState } from "@redux/defaultStates/defaultAlertsState";
import { getBreadcrumbFromState } from "@components/Menu/menuHistoryStateUtils";
import { defaultInitiativesState } from "@redux/defaultStates/defaultInitiativesState";
import { IFileManagerData } from "@components/FileManager/FileManagerModel";
import { getDataFromSession } from "@mede/react-library/utils";
import { Feature } from "@common/feature";
import { defaultCohortsState } from "@redux/defaultStates/defaultCohortsState";
import type { IAxisMetadata, IMeasureMetadata, IMemberMetadata } from "@components/Filters/FiltersModel";
import { defaultEpmState } from "./defaultStates/defaultEpmState";
import defaultBoardState from "@redux/defaultStates/defaultBoardState";

let breadcrumb: BreadcrumbStoredItem[] | null = null;
if (isBackForwardNavigation()) {
	breadcrumb = getBreadcrumbFromState();
}

if (breadcrumb == null) {
	breadcrumb = getDataFromSession<BreadcrumbStoredItem[]>(BREADCRUMB_SESSION_KEY);
}

const clientTitle = getDataFromSession<string>(CLIENT_TITLE_SESSION_KEY);
const leftMenuEnabled = localStorage.getItem(LEFT_MENU_ENABLED_KEY) === "true";
const alertsCount = getDataFromSession<number>(NOT_VIEWED_ALERTS_KEY) ?? 0;
const collaborations = getDataFromSession<number[]>(NOT_VIEWED_COLLABORATIONS_KEY);
const lastAuthId = localStorage.getItem(AUTH_ID_KEY);
const theme = localStorage.getItem(THEME_KEY) ?? "";
const lastTimeFilter = getDataFromSession(LAST_TIME_FILTER_SESSION_KEY);

const initialState = {
	menu: {
		menuData: defaultMenuState,
		isLoading: true,
		product: defaultMenuState.productsData.products[0] as IProductItemDto | null,
		menuBucketCaption: "" as string | null,
		breadcrumbHistory: breadcrumb ?? [],
		expectedBreadcrumbDiscover: null as BreadcrumbStoredItem | null,
		alertsCount: alertsCount,
		collaborations: collaborations ?? []
	},
	app: {
		configuration: {
			clientTitle,
			leftMenuEnabled,
			theme,
			authId: "unknown",
			epm: null,
			minSearchTextLength: 2,
			features: [] as Array<Feature>
		} as IAppConfiguration,
		lastAuthId: lastAuthId,
		isLoggedIn: true,
		isProdMode: process.env.NODE_ENV === "production",
		isLegacyAppMode: false,
		isExpired: false,
		isAboutToExpire: false,
		isLoading: false,
		shareUsers: [] as Array<IShareUser>,
		leavingConfirmationNeeded: false
	},
	tracker: {
		isDeliveryStarted: false,
		processingTime: 0,
		totalDeliveryTime: 0
	},
	dialog: {
		dialogs: new Array<DialogEntity>()
	},
	insight: {
		currentBook: null as string | null,
		filterData: {
			isConfigurationLoaded: false,
			metadata: {} as unknown,
			defaultFilter: null,
			appliedFilterState: lastTimeFilter
		} as IFilterDataModel,
		panel: defaultInsightPanelData,
		library: defaultInsightLibraryData,
		analyst: defaultInsightAnalystData,
		alertCounts: {} as Dictionary<number>
	},
	alerts: defaultAlertsState,
	initiatives: defaultInitiativesState,
	fileManager: {
		filesTree: null,
		hasEditAccess: false,
		editFileDialogProps: { isOpen: false },
		moveFileDialogProps: { isOpen: false },
		configuration: {
			maxUploadFileSizeMb: 1,
			maxUploadFilesCount: 1
		}
	} as IFileManagerData,
	cohorts: defaultCohortsState,
	filters: {
		dimensionsByReport: {} as Dictionary<IAxisMetadata[]>,
		measuresByReport: {} as Dictionary<IMeasureMetadata[]>,
		membersByAxis: {} as Dictionary<IMemberMetadata[]>
	},
	board: defaultBoardState,
	epm: defaultEpmState
};

export default initialState;

export function isDefaultMenu(menu: IMenuModel): boolean {
	return menu.productsData.products[0]?.id === defaultMenuState.productsData.currentProductId;
}
