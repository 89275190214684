import { useAppSelector } from "@redux/reduxHooks";
import { Overlay2 } from "@blueprintjs/core";
import { MedeSpinner } from "@mede/react-library/components";

export default function AppLoader({ container }: { container?: HTMLElement }) {
	const isLoading = useAppSelector(s => s.app.isLoading);

	return (
		<Overlay2 isOpen={isLoading} backdropClassName="page-spinner-backdrop" portalContainer={container}>
			<div className="page-spinner">
				<MedeSpinner aria-label="Loading page" intent="primary" size={"l"} />
			</div>
		</Overlay2>
	);
}
