import { Intent } from "@blueprintjs/core";

export enum DialogType {
	Frame = 0,
	Alert = 1,
	Confirm = 2
}

export class DialogEntity {
	public readonly id: number;
	public readonly dialogType: DialogType;
	public settings: IDialogSettings;
	public isOpen: boolean;
	public dialogResizeTrigger: string | null;
	public getReturnModel: () => any;

	constructor(id: number, dialogType: DialogType, settings: IDialogSettings, defaultReturnModel?: any) {
		this.id = id;
		this.dialogType = dialogType;
		this.settings = { ...defaultSettings, ...settings };
		this.isOpen = true;
		this.dialogResizeTrigger = null;

		let returnModel: any = defaultReturnModel ?? null;
		if (defaultReturnModel == null && dialogType !== DialogType.Frame) {
			returnModel = { confirmValue: false };
		}

		this.getReturnModel = () => returnModel ?? null;
	}
}

const defaultSettings: Partial<IDialogSettings> = {
	isHideByEscKey: true,
	closeOnBackdrop: true,
	okButtonIntent: "primary"
};

export interface IDialogSettings {
	url?: string;
	width?: number;
	height?: number;
	closeOnBackdrop?: boolean;
	returnResultCallback?: (data: any) => void;
	closeDialogCallback?: () => void;
	isHideByEscKey?: boolean;
	contentReadyCallback?: () => void;
	fitDialogContent?: boolean;
	dialogClass?: string;
	returnFocusTargetSelector?: string;
	headerText?: string;
	messageText?: string;
	okButtonText?: string;
	okButtonIntent?: Intent;
	cancelButtonText?: string;
	contentModel?: any;
}
