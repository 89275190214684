import {
	ICohortEntity,
	ICohortCreator,
	ICohortTable,
	IReportInfo,
	ICohortsConfiguration,
	CohortConfigProperties
} from "@components/Cohorts/CohortModel";
import { ICohortMetricData } from "@components/Cohorts/cohortsApi";

export interface ICohortsStoreState {
	table: ICohortTable;
	dataSets: IReportInfo[];
	currentCohort: ICohortEntity | null;
	cohortCreators: ICohortCreator[];
	cohortDimensions: IReportInfo[];
	cohortReports: IReportInfo[];
	cohortCategories: string[];
	config: ICohortsConfiguration | null;
	properties: CohortConfigProperties;
	isLoading: boolean;
	metricsByCohort: Dictionary<ICohortMetricData[]>;
	isFiltersLoading: boolean;
}

export const defaultCohortsState = {
	table: {
		isLoaded: false,
		rows: [],
		rowsPerPage: 50,
		totalRows: 0
	} as ICohortTable,
	dataSets: [] as IReportInfo[],
	currentCohort: null as ICohortEntity | null,
	cohortCreators: [] as ICohortCreator[],
	cohortDimensions: [] as IReportInfo[],
	cohortReports: [] as IReportInfo[],
	cohortCategories: [] as string[],
	config: null as ICohortsConfiguration | null,
	properties: { filtersMaxNumber: 10 },
	isLoading: true,
	metricsByCohort: {} as Dictionary<ICohortMetricData[]>,
	isFiltersLoading: true
} as ICohortsStoreState;
