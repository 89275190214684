import { AppStore } from "@redux/store";
import { DialogType } from "@components/Dialog/DialogEntity";
import {
	closeDialog,
	openDialog,
	setDialogReturnModel,
	triggerDialogResize,
	updateDialog
} from "@components/Dialog/dialogSlice";
import { v4 as guid } from "uuid";
import { Intent } from "@blueprintjs/core";

const DEFAULT_FRAME_WIDTH = 700;
const DEFAULT_FRAME_HEIGHT = 650;

export class LegacyDialogManager {
	private store: AppStore;

	constructor(store: AppStore) {
		this.store = store;
	}

	public showDialog(settings: ILegacyDialogSettings): LegacyDialog {
		const isFrame = settings.dialogType === DialogType.Frame;
		const dialogType = (settings.dialogType as DialogType) ?? DialogType.Frame;
		this.store.dispatch(
			openDialog(
				dialogType,
				{
					url: settings.uri,
					width: settings.containerWidth || !isFrame ? settings.containerWidth : DEFAULT_FRAME_WIDTH,
					height: settings.containerHeight || !isFrame ? settings.containerHeight : DEFAULT_FRAME_HEIGHT,
					closeOnBackdrop: !settings.notCloseOnBackDropClick,
					returnResultCallback: settings.returnResultCallback,
					closeDialogCallback: settings.closeDialogCallback,
					isHideByEscKey: settings.isHideByEscKey ?? true,
					contentReadyCallback: settings.contentReadyCallback,
					fitDialogContent: settings.isChangeDialogSizeByContent ?? false,
					dialogClass: settings.dialogClass,
					returnFocusTargetSelector: this.generateQuerySelector(settings.focusTarget),
					contentModel: dialogType === DialogType.Frame ? settings.contentModel : null,
					headerText: settings.contentModel?.headerValue,
					messageText: settings.contentModel?.messageValue,
					okButtonText: settings.contentModel?.okButtonText,
					cancelButtonText: settings.contentModel?.cancelButtonText,
					okButtonIntent: this.getOkButtonIntent(settings)
				},
				settings.returnModel
			)
		);

		const dialogs = this.store.getState().dialog.dialogs;
		const dialogId = dialogs[dialogs.length - 1].id;
		return new LegacyDialog(dialogId, this.store);
	}

	public hideDialogByIndex(index: number) {
		this.store.dispatch(closeDialog(index));
	}

	public closeDialogByIndex(index: number) {
		this.store.dispatch(closeDialog(index));
	}

	public getMyIndex(elements: HTMLElement[]): number {
		return parseInt(elements[0].getAttribute("data-dialog-id")!);
	}

	public getDialogByIndex(id: number) {
		return new LegacyDialog(id, this.store);
	}

	public enums = {
		dialogType: {
			FRAME: DialogType.Frame,
			ALERT: DialogType.Alert,
			CONFIRM: DialogType.Confirm
		},
		buttonCoolorStyle: { PRIMARY: "btn-primary" },
		alertCoolorStyle: {}
	};

	private generateQuerySelector(element: HTMLElement | undefined): string | undefined {
		if (element == null) {
			return undefined;
		}

		const jqueryElement = element as any;
		if (jqueryElement.jquery) {
			element = jqueryElement[0] as HTMLElement | undefined;
		}

		if (element == null) {
			return undefined;
		}

		const focusId = guid();
		element.setAttribute("data-focus-id", focusId);
		return `[data-focus-id='${focusId}']`;
	}

	private getOkButtonIntent(settings: ILegacyDialogSettings): Intent {
		switch (settings.dialogType) {
			case DialogType.Confirm:
				return settings.contentModel?.okButtonCoolorStyle === "btn-primary" ? "primary" : "danger";
			case DialogType.Alert:
				return "primary";
			default:
				return "none";
		}
	}
}

class LegacyDialog {
	private readonly dialogId: number;
	private readonly store: AppStore;

	constructor(dialogId: number, store: AppStore) {
		this.dialogId = dialogId;
		this.store = store;
	}

	public setHeightDialog(height: number): void {
		this.store.dispatch(updateDialog(this.dialogId, { height }));
	}

	public set returnModel(data: any) {
		this.store.dispatch(setDialogReturnModel(this.dialogId, data));
	}

	public get returnModel(): any {
		const dialogs = this.store.getState().dialog.dialogs;
		const dialog = dialogs.find(x => x.id === this.dialogId);
		return dialog?.getReturnModel();
	}

	public get contentModel(): any {
		const dialogs = this.store.getState().dialog.dialogs;
		const dialog = dialogs.find(x => x.id === this.dialogId);
		return dialog?.settings.contentModel ?? {};
	}

	public changeDialogSizeByContent() {
		this.store.dispatch(triggerDialogResize(this.dialogId));
	}

	public getContentWindow(): Window {
		const portal = document.getElementById("main-menu-portal")!;
		const dialog = portal.shadowRoot!.querySelector(`[data-dialog-id='${this.dialogId}']`) as HTMLIFrameElement;
		return dialog.contentWindow!;
	}
}

export interface ILegacyDialogSettings {
	uri: string;
	containerWidth?: number;
	containerHeight?: number;
	notCloseOnBackDropClick?: boolean;
	dialogType?: number;
	isHideByEscKey?: boolean;
	contentModel?: {
		headerValue?: string;
		messageValue?: string;
		okButtonText?: string;
		cancelButtonText?: string;
		okButtonCoolorStyle: string;
	};
	returnModel?: any;
	closeDialogCallback?: any;
	returnResultCallback?: any;
	contentReadyCallback?: any;
	isChangeDialogSizeByContent?: boolean;
	backdropClass?: string;
	dialogClass?: string;
	focusTarget?: HTMLElement;
}
