import "@mede/react-library/styles/mede-style";
import "./Common.scss";
import "./App.scss";
import { lazy, Suspense, useEffect } from "react";
import { loadConfiguration } from "./appSlice";
import { useAppDispatch, useAppSelector } from "@redux/reduxHooks";
import PageContent from "../PageContent/PageContent";
import SessionExpirationDialog from "@components/App/SessionExpiration/SessionExpirationDialog";
import useSessionExpiration from "@components/App/SessionExpiration/useSessionExpiration";
import useEventNotifier from "@components/App/EventNotifier/useEventNotifier";
import DialogsContainer from "@components/Dialog/DialogsContainer";
import { initPendoService } from "@components/App/PendoService";
import { showSystemAlerts } from "@components/Dialog/dialogSlice";
import MenuGeneric from "@components/Menu/MenuGeneric";
import { setTheme } from "@mede/react-library/utils";
import AppLoader from "@components/App/AppLoader";
import AppFooter from "@components/AppFooter/AppFooter";
import useInitiatives from "@components/App/Initiatives/useInitiatives";
import PromptDialogsContainer from "@components/Dialog/PromptDialogsContainer";

const LoginPage = lazy(() => import("@components/Login/LoginPage"));

export default function App() {
	const isLoggedIn = useAppSelector(s => s.app.isLoggedIn);
	const isProdMode = useAppSelector(s => s.app.isProdMode);
	const isExpired = useAppSelector(s => s.app.isExpired);
	const isReady = useAppSelector(s => s.app.configuration.hasAccess);
	const isLeftMenuEnabled = useAppSelector(s => s.app.configuration.leftMenuEnabled);
	const theme = useAppSelector(s => s.app.configuration.theme);
	const dispatch = useAppDispatch();

	useSessionExpiration();
	useEventNotifier();
	useInitiatives();

	useEffect(() => {
		setTheme(theme);
	}, [theme]);

	useEffect(() => {
		if (isLoggedIn) {
			dispatch(loadConfiguration());
		}
	}, [isLoggedIn]);

	useEffect(() => {
		if (isReady && isLoggedIn) {
			initPendoService();
			dispatch(showSystemAlerts());
		}
	}, [isReady, isLoggedIn]);

	if (!isLoggedIn) {
		if (!isProdMode) {
			return (
				<Suspense>
					<LoginPage />
				</Suspense>
			);
		}

		return;
	}

	if (isExpired && isReady) {
		return <SessionExpirationDialog />;
	}

	return (
		<div id="App" className={isLeftMenuEnabled ? "left-menu-layout" : "top-menu-layout"}>
			<MenuGeneric />
			<PageContent />
			<AppFooter />
			<DialogsContainer />
			<PromptDialogsContainer />
			<AppLoader />
		</div>
	);
}
